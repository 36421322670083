import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import {
  Container,
  Wrapper,
  Column,
  Text,
  Subtitle,
  SEOTitle,
} from "../../theme"
import { Colors, Fonts, Responsive } from "../../theme/styles"
import { CallToAction } from "../../components"
import {
  FaLaptopCode,
  FaDraftingCompass,
  FaEnvelope,
  FaHashtag,
} from "react-icons/fa"

const ServiceCard = styled.div`
  ${Responsive.sm`
display: flex;
justify-content: center;
background: ${Colors.darkBlue};
align-items: center;
border: 2px solid ${Colors.lightBlue};
width: 100%;
height: 100%;
color: ${Colors.white};
margin-top: 30px;
padding: 20px;
margin-bottom: 30px;
font-family: ${Fonts.montserrat};
cursor: pointer;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
transition: 0.3s;
&:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: ${Colors.white};
  color: ${Colors.black};
}
`}
  ${Responsive.lg`
margin-top: 0px;
margin-bottom: 0px;
`}
`
const Grid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 300px);
gap: 45px;
width: 100%;
margin-top: 20px;
justify-content: center;
padding-bottom: 40px;
`}
  ${Responsive.md`
grid-template-columns: repeat(auto-fit, 300px);
`}
  ${Responsive.lg`
display: grid;
grid-template-columns: repeat(auto-fit, 425px);
gap: 45px;
width: 100%;
margin-top: 30px;
justify-content: center;
`}
`

const StyledLi = styled(Text)`
  margin-top: 10px;
  font-size: 20px;
  color: inherit;
`

const StyledText = styled(Subtitle)`
  color: inherit;
  margin-top: 10px;
  font-size: 22px;s
`

const data = [
  {
    icon: <FaDraftingCompass size={60} />,
    service: "Company Branding",
    list1: "Guidance on how to set up your company branding",
    margin: true,
  },
  {
    icon: <FaLaptopCode size={60} />,
    service: "Steps to Set-Up Your Website",
    list1:
      "Checklist for steps to get your website online for your customers to see",
    margin: true,
  },
  {
    icon: <FaHashtag size={60} />,
    service: "Social Media Creation",
    list1:
      "Steps to take to find out which social media platforms to find your audience",
    margin: true,
  },
  {
    icon: <FaEnvelope size={60} />,
    service: "Local Site Directories",
    list1:
      "Learn what local site directories are important for SEO to be found on Google",
    margin: true,
  },
]

const Checklist = () => {
  return (
    <Layout
      type="landingPage"
      title="FREE Ultimate Starter Checklist"
      subtitle="Take actionable steps to get your business running online"
    >
      <Wrapper>
        <Container>
          <Column>
            <SEOTitle>How this checklist helps you</SEOTitle>
            <Grid>
              {data.map((key, index) => {
                return (
                  <ServiceCard key={index}>
                    <Column
                      alignitems="center"
                      justifycontent="center"
                      width="90%"
                      margin="0px auto"
                    >
                      {key.icon}
                      <StyledText textAlign="center">{key.service}</StyledText>
                      <StyledLi>{key.list1}</StyledLi>
                    </Column>
                  </ServiceCard>
                )
              })}
            </Grid>
          </Column>
        </Container>
      </Wrapper>
      <CallToAction type="landingpage" />
    </Layout>
  )
}

export default Checklist
